const Header = () => {
    return (
        <section id="nav" className="nav-container">
            <nav className="navbar navbar-default navbar-static-top">
                <div className="container">
                    <div className="navbar-header">
                        <button
                            type="button"
                            className="navbar-toggle collapsed"
                            data-toggle="collapse"
                            data-target="#bs-example-navbar-collapse-1"
                            aria-expanded="false"
                        >
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </button>
                    </div>

                    <div
                        className="collapse navbar-collapse"
                        id="bs-example-navbar-collapse-1"
                    >
                        <ul className="nav navbar-nav navbar-right">
                            <li>
                                <a href="/peeplistcheck">PeepList Check</a>
                            </li>
                            <li>
                                <a href="/#about">About</a>
                            </li>
                            <li>
                                <a href="/#roadmap">Roadmap</a>
                            </li>
                            <li>
                                <a href="/#peeps">Peeps</a>
                            </li>
                            <li>
                                <a href="/#team">Team</a>
                            </li>
                            <li>
                                <a href="/#community">Community</a>
                            </li>
                            <li>
                                <a href="/#story">Story</a>
                            </li>
                            <li>
                                <a href="/contact">Contacts</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </section>
    );
};
export default Header;
