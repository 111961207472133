import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import abi from '../contracts/abi';
import { ethers } from 'ethers';
import toast, { Toaster } from 'react-hot-toast';
import * as Constants from '../constants/config';
import '../App.scss';

const contractAddress = Constants.CONTRACT_ADDRESS;

const Nft = () => {
    document.body.classList.add('dashboard-page');

    const [currentAccount, setCurrentAccount] = useState(null);
    const [mintTotal, setMintTotal] = useState(0);
    const [publicMintTotal, setPublicMintTotal] = useState(0);
    const [whitelistMintTotal, setWhitelistMintTotal] = useState(0);
    const [ogSaleMintTotal, setOgSaleMintTotal] = useState(0);
    const [reservedSupplyCount, setReservedSupplyCount] = useState(0);
    const [airdropTotalMintSupply, setAirdropTotalMintSupply] = useState(0);
    const [maxSupply, setMaxSupply] = useState(0);
    const [tokenPrice, setTokenPrice] = useState(0);
    const [presalePrice, setPresalePrice] = useState(0);
    const [ogSalePrice, setOgSalePrice] = useState(0);
    const [contractBalance, setContractBalance] = useState(0);
    const [tokenNewCost, setTokenNewCost] = useState(0);
    const [whitelistTokenNewCost, setWhitelistTokenNewCost] = useState(0);
    const [ogSaleTokenNewCost, setOgSaleTokenNewCost] = useState(0);
    const [ownerAddress, setOwnerAddress] = useState(0);
    const [adresstoAirdrop, setAdresstoAirdrop] = useState(null);
    const [qtytoAirdrop, setQtytoAirdrop] = useState(null);
    const [noOfTokenToReserve, setNoOfTokenToReserve] = useState(0);
    const [revealStatus, setRevealStatus] = useState(0);
    const [preSaleRoot, setPreSaleRoot] = useState(null);
    const [baseUri, setBaseUri] = useState(null);
    const [nonRevealUri, setNonRevealUri] = useState(null);
    const [rootHash, setRootHashValue] = useState(null);
    const [freeOneTotalMintSupply, setFreeOneTotalMintSupply] = useState(null);
    const [whitelistMaxMint, setWhitelistMaxMint] = useState(0);
    const [ogSaleMaxMint, setOgSaleMaxMint] = useState(0);
    const [newWhitelistMaxMint, setNewWhitelistMaxMint] = useState(0);
    const [newOgSaleMaxMint, setNewOgSaleMaxMint] = useState(0);
    const [publicMaxMint, setPublicMaxMint] = useState(0);
    const [newPublicMaxMint, setNewPublicMaxMint] = useState(0);
    const [royaltyPercent, setRoyaltyPercent] = useState(0);
    const [newRoyaltyPercent, setNewRoyaltyPercent] = useState(0);
    const [presaleStatus, setPresaleStatus] = useState(null);
    const [ogSaleStatus, setOgSaleStatus] = useState(null);
    const [publicSaleStatus, setPublicSaleStatus] = useState(null);
    const [freeSaleStatus, setFreeSaleStatus] = useState(null);
    const [mode, setMode] = useState(null);
    const history = useNavigate();

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const nftContract = new ethers.Contract(contractAddress, abi, signer);

    useEffect(() => {
        getToken();
        checkWalletIsConnected();
        getConfig();
        document.body.style.backgroundImage = 'none';
        // eslint-disable-next-line
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getToken = async () => {
        if (localStorage.getItem('valid_account') !== 'verified') {
            history('/');
        }
    };

    const getConfig = async () => {
        try {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const contract = new ethers.Contract(
                contractAddress,
                abi,
                provider
            );
            setMintTotal((await contract.totalSupply()).toNumber());
            setMaxSupply((await contract.MAX_SUPPLY()).toNumber());
            setContractBalance((await contract.getBalance()).toString() / 1e18);
            setTokenPrice((await contract.PUBLIC_PRICE()).toString() / 1e18);
            setPresalePrice((await contract.PRESALE_PRICE()).toString() / 1e18);
            setOgSalePrice((await contract.OGSALE_PRICE()).toString() / 1e18);
            setPublicMintTotal((await contract._publicCounter()).toNumber());
            setReservedSupplyCount(
                (await contract._reserveCounter()).toNumber()
            );
            setWhitelistMintTotal(
                (await contract._preSaleCounter()).toNumber()
            );
            setOgSaleMintTotal((await contract._ogSaleCounter()).toNumber());
            setAirdropTotalMintSupply(
                (await contract._airdropCounter()).toNumber()
            );
            setFreeOneTotalMintSupply(
                (await contract._freeSaleCounter()).toNumber()
            );
            setRevealStatus(await contract._revealed());
            setOwnerAddress(await contract.owner());
            setPreSaleRoot(await contract.merkleRoot());
            setPresaleStatus(await contract._preSaleActive());
            setOgSaleStatus(await contract._ogSaleActive());
            setPublicSaleStatus(await contract._publicSaleActive());
            setFreeSaleStatus(await contract._freeSaleActive());
            setWhitelistMaxMint(
                (await contract.maxPreSaleMintAmount()).toNumber()
            );
            setPublicMaxMint(
                (await contract.maxPublicSaleMintAmount()).toNumber()
            );
            setOgSaleMaxMint((await contract.maxOgSaleMintAmount()).toNumber());
            setRoyaltyPercent((await contract.royaltyPercent()).toNumber());
        } catch (error) {
            console.log(error);
        }
    };

    const checkWalletIsConnected = async () => {
        const { ethereum } = window;

        if (!ethereum) {
            console.log('Make sure you have Metamask installed!');
            toast.error('Make sure you have Metamask installed!');
            return;
        } else {
            console.log("Wallet exists! We're ready to go!");
        }

        const accounts = await ethereum.request({ method: 'eth_accounts' });

        if (accounts.length !== 0) {
            setCurrentAccount(accounts[0]);
        } else {
            console.log('No authorized account found');
        }
    };

    const connectWalletHandler = async () => {
        const { ethereum } = window;

        if (!ethereum) {
            toast.error('Please install Metamask!');
        }

        try {
            const accounts = await ethereum.request({
                method: 'eth_requestAccounts'
            });
            if (ownerAddress === accounts[0]) {
                setCurrentAccount(accounts[0]);
            } else {
                toast.error('Please connect owner wallet');
            }
            //alert.show("Found an account! Address: ", accounts[0]);
        } catch (err) {
            console.log(err);
        }
    };

    // on off meta data
    const switchRevealHandler = async e => {
        try {
            const { ethereum } = window;

            if (ethereum) {
                if (revealStatus) {
                    let nftTxn = await nftContract.reveal(false);
                    toast.loading('Please wait...');
                    await nftTxn.wait();
                    toast.success('Updated');
                } else {
                    let nftTxn = await nftContract.reveal(true);
                    toast.loading('Please wait...');
                    await nftTxn.wait();
                    window.location.reload(false);
                    toast.success('Updated');
                }
            } else {
                toast.error('Ethereum object does not exist');
            }
        } catch (err) {
            toast.error(err.error.message.substr(19));
        }
    };

    const switchPublicSaleModeHandler = async () => {
        try {
            const { ethereum } = window;

            if (ethereum) {
                let nftTxn = await nftContract.setPublicSaleActive(mode);
                toast.loading('Please wait...');
                await nftTxn.wait();
                toast.success('Updated');
            } else {
                toast.error('Ethereum object does not exist');
            }
        } catch (err) {
            toast.error(err.error.message.substr(19));
        }
    };

    const switchPreSaleModeHandler = async () => {
        try {
            const { ethereum } = window;

            if (ethereum) {
                let nftTxn = await nftContract.setPreSaleActive(mode);
                toast.loading('Please wait...');
                await nftTxn.wait();
                toast.success('Updated');
            } else {
                toast.error('Ethereum object does not exist');
            }
        } catch (err) {
            toast.error(err.error.message.substr(19));
        }
    };

    const switchOgSaleModeHandler = async () => {
        try {
            const { ethereum } = window;

            if (ethereum) {
                let nftTxn = await nftContract.setOgSaleActive(mode);
                toast.loading('Please wait...');
                await nftTxn.wait();
                toast.success('Updated');
            } else {
                toast.error('Ethereum object does not exist');
            }
        } catch (err) {
            toast.error(err.error.message.substr(19));
        }
    };

    const switchFreeSaleModeHandler = async () => {
        try {
            const { ethereum } = window;

            if (ethereum) {
                let nftTxn = await nftContract.setFreeSaleActive(mode);
                toast.loading('Please wait...');
                await nftTxn.wait();
                toast.success('Updated');
            } else {
                toast.error('Ethereum object does not exist');
            }
        } catch (err) {
            toast.error(err.error.message.substr(19));
        }
    };

    const updateRootHandler = async () => {
        try {
            const { ethereum } = window;

            if (!rootHash || rootHash === '0') {
                toast.error('Please enter root hash', { id: 'error' });
                return false;
            }
            // const rootHashVal = '0x' + rootHash;  // Shan's implementation
            const rootHashVal = rootHash; // SanLeo implementation
            if (ethereum) {
                let nftTxn = await nftContract.setMerkleRoot(rootHashVal);

                toast.loading('Please wait...');
                await nftTxn.wait();
                window.location.reload(false);
                toast.success('Updated');
            } else {
                toast.error('Ethereum object does not exist');
            }
        } catch (err) {
            toast.error(err.error.message.substr(19));
        }
    };

    const updateTokenCostHandler = async () => {
        try {
            const { ethereum } = window;

            if (!tokenNewCost || tokenNewCost === '0') {
                toast.error('Please enter a value', { id: 'error' });
                return false;
            }
            if (ethereum) {
                let nftTxn = await nftContract.setPublicMintPrice(tokenNewCost);

                toast.loading('Please wait...');
                await nftTxn.wait();
                toast.success('Updated');
                window.location.reload(false);
            } else {
                toast.error('Ethereum object does not exist');
            }
        } catch (err) {
            toast.error(err.error.message.substr(19));
        }
    };

    const updateWhitelistTokenCostHandler = async () => {
        try {
            const { ethereum } = window;

            if (!whitelistTokenNewCost || whitelistTokenNewCost === '0') {
                toast.error('Please enter a value', { id: 'error' });
                return false;
            }
            if (ethereum) {
                let nftTxn = await nftContract.setPresaleMintPrice(
                    whitelistTokenNewCost
                );

                toast.loading('Please wait...');
                await nftTxn.wait();
                toast.success('Updated');
                window.location.reload(false);
            } else {
                toast.error('Ethereum object does not exist');
            }
        } catch (err) {
            toast.error(err.error.message.substr(19));
        }
    };

    const updateOgSaleTokenCostHandler = async () => {
        try {
            const { ethereum } = window;

            if (!ogSaleTokenNewCost || ogSaleTokenNewCost === '0') {
                toast.error('Please enter a value', { id: 'error' });
                return false;
            }
            if (ethereum) {
                let nftTxn = await nftContract.setOgSaleMintPrice(
                    ogSaleTokenNewCost
                );

                toast.loading('Please wait...');
                await nftTxn.wait();
                toast.success('Updated');
                window.location.reload(false);
            } else {
                toast.error('Ethereum object does not exist');
            }
        } catch (err) {
            toast.error(err.error.message.substr(19));
        }
    };

    const WithdrawHandler = async () => {
        try {
            const { ethereum } = window;

            if (ethereum) {
                let nftTxn = await nftContract.withdraw();

                toast.loading('Please wait...');
                await nftTxn.wait();
            } else {
                toast.error('Ethereum object does not exist');
            }
        } catch (err) {
            toast.error(err.error.message.substr(19));
        }
    };

    const updateNonRevealUriHandler = async () => {
        try {
            const { ethereum } = window;

            if (!nonRevealUri || nonRevealUri === '0') {
                toast.error('Please enter wallet address');
                return false;
            }
            if (ethereum) {
                let nftTxn = await nftContract.setNotRevealedURI(nonRevealUri);

                toast.loading('Please wait...');
                await nftTxn.wait();
                toast.success('Updated!');
            } else {
                toast.error('Ethereum object does not exist');
            }
        } catch (err) {
            toast.error(err.error.message.substr(19));
        }
    };

    const updateMetaUriHandler = async () => {
        try {
            const { ethereum } = window;

            if (!baseUri || baseUri === '0') {
                toast.error('Please enter wallet address');
                return false;
            }
            if (ethereum) {
                let nftTxn = await nftContract.setBaseURI(baseUri);

                toast.loading('Please wait...');
                await nftTxn.wait();
                toast.success('Updated!');
            } else {
                toast.error('Ethereum object does not exist');
            }
        } catch (err) {
            toast.error(err.error.message.substr(19));
        }
    };

    const airdropTokenHandler = async () => {
        try {
            const { ethereum } = window;

            if (
                (!adresstoAirdrop || adresstoAirdrop === '0') &&
                (!qtytoAirdrop || qtytoAirdrop === '0')
            ) {
                toast.error('Please enter wallet address');
                return false;
            }
            var addresses = adresstoAirdrop.split(',');
            if (ethereum) {
                let nftTxn = await nftContract.airDrop(addresses, qtytoAirdrop);

                toast.loading('Please wait...');
                await nftTxn.wait();
                window.location.reload(false);
                toast.success('Sent!');
            } else {
                toast.error('Ethereum object does not exist');
            }
        } catch (err) {
            toast.error(err.error.message.substr(19));
        }
    };

    const reserveTokenHandler = async () => {
        try {
            const { ethereum } = window;

            if (!noOfTokenToReserve || noOfTokenToReserve === '0') {
                toast.error('Please enter a value');
                return false;
            }
            if (ethereum) {
                let nftTxn = await nftContract.reserveMint(noOfTokenToReserve);

                toast.loading('Please wait...');
                await nftTxn.wait();
                window.location.reload(false);
                toast.success('Reserved!');
            } else {
                toast.error('Ethereum object does not exist');
            }
        } catch (err) {
            toast.error(err.error.message.substr(19));
        }
    };

    const updatePublicMaxMintHandler = async () => {
        try {
            const { ethereum } = window;

            if (!newPublicMaxMint || newPublicMaxMint === '0') {
                toast.error('Please enter a value');
                return false;
            }
            if (ethereum) {
                let nftTxn = await nftContract.setPublicSaleMintAmount(
                    newPublicMaxMint
                );

                toast.loading('Please wait...');
                await nftTxn.wait();
                window.location.reload(false);
                toast.success('Updated');
            } else {
                toast.error('Ethereum object does not exist');
            }
        } catch (err) {
            toast.error(err.error.message.substr(19));
        }
    };

    const updateWhitelistMaxMintHandler = async () => {
        try {
            const { ethereum } = window;

            if (!newWhitelistMaxMint || newWhitelistMaxMint === '0') {
                toast.error('Please enter a value');
                return false;
            }
            if (ethereum) {
                let nftTxn = await nftContract.setMaxPresaleMintAmount(
                    newWhitelistMaxMint
                );

                toast.loading('Please wait...');
                await nftTxn.wait();
                window.location.reload(false);
                toast.success('Updated');
            } else {
                toast.error('Ethereum object does not exist');
            }
        } catch (err) {
            toast.error(err.error.message.substr(19));
        }
    };

    const updateOgSaleMaxMintHandler = async () => {
        try {
            const { ethereum } = window;

            if (!newOgSaleMaxMint || newOgSaleMaxMint === '0') {
                toast.error('Please enter a value');
                return false;
            }
            if (ethereum) {
                let nftTxn = await nftContract.setOgSaleMintAmount(
                    newOgSaleMaxMint
                );

                toast.loading('Please wait...');
                await nftTxn.wait();
                window.location.reload(false);
                toast.success('Updated');
            } else {
                toast.error('Ethereum object does not exist');
            }
        } catch (err) {
            toast.error(err.error.message.substr(19));
        }
    };

    const updateRoyaltyPercentageHandler = async () => {
        try {
            const { ethereum } = window;

            if (!newRoyaltyPercent || newRoyaltyPercent === '0') {
                toast.error('Please enter a value');
                return false;
            }
            if (ethereum) {
                let nftTxn = await nftContract.setRoyaltyPercentage(
                    newRoyaltyPercent
                );

                toast.loading('Please wait...');
                await nftTxn.wait();
                window.location.reload(false);
                toast.success('Updated');
            } else {
                toast.error('Ethereum object does not exist');
            }
        } catch (err) {
            toast.error(err.error.message.substr(19));
        }
    };

    const connectWalletButton = () => {
        return (
            <button onClick={connectWalletHandler} className="btn btn-danger ">
                Connect Owner Wallet
            </button>
        );
    };

    const updateTokenCost = () => {
        return (
            <button
                onClick={updateTokenCostHandler}
                className="btn btn-success"
            >
                Update
            </button>
        );
    };

    const updateWhitelistTokenCost = () => {
        return (
            <button
                onClick={updateWhitelistTokenCostHandler}
                className="btn btn-success"
            >
                Update
            </button>
        );
    };

    const updateOgSaleTokenCost = () => {
        return (
            <button
                onClick={updateOgSaleTokenCostHandler}
                className="btn btn-success"
            >
                Update
            </button>
        );
    };

    const updateRootHash = () => {
        return (
            <button onClick={updateRootHandler} className="btn btn-success">
                Update Root Hash
            </button>
        );
    };

    const reserveToken = () => {
        return (
            <button onClick={reserveTokenHandler} className="btn btn-success">
                Reserve
            </button>
        );
    };

    const switchReveal = () => {
        return (
            <button onClick={switchRevealHandler} className="btn btn-success">
                Reveal Metadata
            </button>
        );
    };

    const airdropToken = () => {
        return (
            <button onClick={airdropTokenHandler} className="btn btn-success">
                Send
            </button>
        );
    };

    const updatePublicSaleMaxMint = () => {
        return (
            <button
                onClick={updatePublicMaxMintHandler}
                className="btn btn-success"
            >
                Update
            </button>
        );
    };

    const updatePresaleMaxMint = () => {
        return (
            <button
                onClick={updateWhitelistMaxMintHandler}
                className="btn btn-success"
            >
                Update
            </button>
        );
    };

    const updateOgSaleMaxMint = () => {
        return (
            <button
                onClick={updateOgSaleMaxMintHandler}
                className="btn btn-success"
            >
                Update
            </button>
        );
    };

    const updateRoyaltyPercentage = () => {
        return (
            <button
                onClick={updateRoyaltyPercentageHandler}
                className="btn btn-success"
            >
                Update
            </button>
        );
    };

    const Withdraw = () => {
        return (
            <button onClick={WithdrawHandler} className="btn btn-danger">
                Withdraw <span className="badge">{contractBalance} ETH</span>
            </button>
        );
    };

    const updateUri = () => {
        return (
            <button onClick={updateMetaUriHandler} className="btn btn-success">
                Update
            </button>
        );
    };

    const updateNonRevealUri = () => {
        return (
            <button
                onClick={updateNonRevealUriHandler}
                className="btn btn-success"
            >
                Update
            </button>
        );
    };

    return (
        <div className="container mt-5 admin-bg-bolor">
            <Toaster
                position="top-center"
                reverseOrder={false}
                gutter={8}
                containerClassName=""
                containerStyle={{}}
                toastOptions={{
                    // Define default options
                    className: '',
                    duration: 5000,
                    style: {
                        background: '#0982e1',
                        color: '#fff'
                    },
                    // Default options for specific types
                    success: {
                        duration: 3000,
                        theme: {
                            primary: 'green',
                            secondary: 'black'
                        }
                    }
                }}
            />
            {currentAccount ? (
                ''
            ) : (
                <div className="buttons">{connectWalletButton()} </div>
            )}

            <div className="row">
                <div className="col-md-6">
                    <div className="list-group">
                        <a
                            href="/#"
                            className="list-group-item disabled"
                            rel="noreferrer"
                        >
                            Token Summary
                        </a>
                        <ul className="list-group">
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                Contract Address
                                <span className="badge badge-primary badge-pill contract-link">
                                    <a
                                        href="https://goerli.etherscan.io/address/0x6ce74d726a02498244990c16d244f301dcba5644"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="contract-link"
                                    >
                                        {contractAddress}
                                    </a>
                                </span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                Account
                                <span className="badge badge-primary badge-pill">
                                    {currentAccount}
                                </span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                Total Max Supply
                                <span className="badge badge-primary badge-pill">
                                    {maxSupply}
                                </span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                Token Cost (Public)
                                <span className="badge badge-primary badge-pill">
                                    {tokenPrice} ETH
                                </span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                Token Cost (Presale)
                                <span className="badge badge-primary badge-pill">
                                    {presalePrice} ETH
                                </span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                Token Cost (OG Sale)
                                <span className="badge badge-primary badge-pill">
                                    {ogSalePrice} ETH
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="list-group">
                        <a
                            href="/#"
                            className="list-group-item disabled"
                            rel="noreferrer"
                        >
                            Minted Summary
                        </a>
                        <ul className="list-group">
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                Total Supply
                                <span className="badge badge-primary badge-pill">
                                    {mintTotal}
                                </span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                Public Supply
                                <span className="badge badge-primary badge-pill">
                                    {publicMintTotal}
                                </span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                Presale Supply
                                <span className="badge badge-primary badge-pill">
                                    {whitelistMintTotal}
                                </span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                OG Sale Supply
                                <span className="badge badge-primary badge-pill">
                                    {ogSaleMintTotal}
                                </span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                Free Sale Supply
                                <span className="badge badge-primary badge-pill">
                                    {freeOneTotalMintSupply}
                                </span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                Reserved Supply
                                <span className="badge badge-primary badge-pill">
                                    {reservedSupplyCount}
                                </span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                Airdrop Supply
                                <span className="badge badge-primary badge-pill">
                                    {airdropTotalMintSupply}
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <div className="alert alert-success">
                        {currentAccount ? Withdraw() : ''}
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-3">
                    <div className="panel panel-default">
                        <p className="panel-heading">
                            Public Sale{' '}
                            {publicSaleStatus === true ? 'ON' : 'OFF'}
                        </p>
                        <div className="panel-body">
                            <div className="form-group">
                                <div className="control">
                                    <input
                                        type="radio"
                                        name="public"
                                        onChange={e => {
                                            setMode(true);
                                        }}
                                    />{' '}
                                    ON
                                </div>
                                <div className="control">
                                    <input
                                        type="radio"
                                        name="public"
                                        onChange={e => {
                                            setMode(false);
                                        }}
                                    />{' '}
                                    OFF
                                </div>
                            </div>
                        </div>
                        <div className="panel-footer">
                            {currentAccount ? (
                                <button
                                    onClick={switchPublicSaleModeHandler}
                                    className="btn btn-success"
                                >
                                    Update
                                </button>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="panel panel-default">
                        <p className="panel-heading">
                            Pre Sale {presaleStatus === true ? 'ON' : 'OFF'}
                        </p>
                        <div className="panel-body">
                            <div className="form-group">
                                <div className="control">
                                    <input
                                        type="radio"
                                        name="presale"
                                        onChange={e => {
                                            setMode(true);
                                        }}
                                    />{' '}
                                    ON
                                </div>
                                <div className="control">
                                    <input
                                        type="radio"
                                        name="presale"
                                        onChange={e => {
                                            setMode(false);
                                        }}
                                    />{' '}
                                    OFF
                                </div>
                            </div>
                        </div>
                        <div className="panel-footer">
                            {currentAccount ? (
                                <button
                                    onClick={switchPreSaleModeHandler}
                                    className="btn btn-success"
                                >
                                    Update
                                </button>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="panel panel-default">
                        <p className="panel-heading">
                            OG Sale {ogSaleStatus === true ? 'ON' : 'OFF'}
                        </p>
                        <div className="panel-body">
                            <div className="form-group">
                                <div className="control">
                                    <input
                                        type="radio"
                                        name="og"
                                        onChange={e => {
                                            setMode(true);
                                        }}
                                    />{' '}
                                    ON
                                </div>
                                <div className="control">
                                    <input
                                        type="radio"
                                        name="og"
                                        onChange={e => {
                                            setMode(false);
                                        }}
                                    />{' '}
                                    OFF
                                </div>
                            </div>
                        </div>
                        <div className="panel-footer">
                            {currentAccount ? (
                                <button
                                    onClick={switchOgSaleModeHandler}
                                    className="btn btn-success"
                                >
                                    Update
                                </button>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="panel panel-default">
                        <p className="panel-heading">
                            Free Sale {freeSaleStatus === true ? 'ON' : 'OFF'}
                        </p>
                        <div className="panel-body">
                            <div className="form-group">
                                <div className="control">
                                    <input
                                        type="radio"
                                        name="free"
                                        onChange={e => {
                                            setMode(true);
                                        }}
                                    />{' '}
                                    ON
                                </div>
                                <div className="control">
                                    <input
                                        type="radio"
                                        name="free"
                                        onChange={e => {
                                            setMode(false);
                                        }}
                                    />{' '}
                                    OFF
                                </div>
                            </div>
                        </div>
                        <div className="panel-footer">
                            {currentAccount ? (
                                <button
                                    onClick={switchFreeSaleModeHandler}
                                    className="btn btn-success"
                                >
                                    Update
                                </button>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <div className="panel panel-default">
                        <p className="panel-heading">Presale RootHash</p>
                        <div className="panel-body">
                            <div className="form-group">
                                <label className="">
                                    Root Hash:{' '}
                                    <span className="label label-primary">
                                        {preSaleRoot}
                                    </span>
                                </label>
                                <div className="control">
                                    <input
                                        className="form-control"
                                        type="text"
                                        onChange={e =>
                                            setRootHashValue(e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="panel-footer">
                            {currentAccount ? updateRootHash() : ''}
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="panel panel-default">
                        <p className="panel-heading">
                            Update Royalty Percentage (value * 10)
                        </p>
                        <div className="panel-body">
                            <div className="field">
                                <label>
                                    Value:{' '}
                                    <span className="label label-primary">
                                        {(royaltyPercent * 100) / 1000} %{' '}
                                    </span>
                                </label>
                                <div className="control">
                                    <input
                                        className="form-control"
                                        type="text"
                                        onChange={e =>
                                            setNewRoyaltyPercent(e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="panel-footer">
                            {currentAccount ? updateRoyaltyPercentage() : ''}
                        </div>
                    </div>
                </div>
            </div>

            <div className="panel panel-default">
                <p className="panel-heading">
                    Update Token Cost (Public)
                    <span className="label label-warning">
                        Add the amount in Wei{' '}
                        <a
                            href="https://eth-converter.com/"
                            rel="noreferrer"
                            target="_blank"
                        >
                            {' '}
                            https://eth-converter.com{' '}
                        </a>{' '}
                    </span>
                </p>
                <div className="panel-body">
                    <div className="field">
                        <label>
                            Value:{' '}
                            <span className="label label-primary">
                                {tokenPrice}
                            </span>
                        </label>
                        <div className="control">
                            <input
                                className="form-control"
                                type="text"
                                onChange={e => setTokenNewCost(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="panel-footer">
                    {currentAccount ? updateTokenCost() : ''}
                </div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <div className="panel panel-default">
                        <p className="panel-heading">
                            Update Token Cost (Presale)
                            <span className="label label-warning">
                                Add the amount in Wei{' '}
                                <a
                                    href="https://eth-converter.com/"
                                    rel="noreferrer"
                                    target="_blank"
                                >
                                    {' '}
                                    https://eth-converter.com{' '}
                                </a>{' '}
                            </span>
                        </p>
                        <div className="panel-body">
                            <div className="field">
                                <label>
                                    Value:{' '}
                                    <span className="label label-primary">
                                        {presalePrice}
                                    </span>
                                </label>
                                <div className="control">
                                    <input
                                        className="form-control"
                                        type="text"
                                        onChange={e =>
                                            setWhitelistTokenNewCost(
                                                e.target.value
                                            )
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="panel-footer">
                            {currentAccount ? updateWhitelistTokenCost() : ''}
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="panel panel-default">
                        <p className="panel-heading">
                            Update Token Cost (OG sale)
                            <span className="label label-warning">
                                Add the amount in Wei{' '}
                                <a
                                    href="https://eth-converter.com/"
                                    rel="noreferrer"
                                    target="_blank"
                                >
                                    {' '}
                                    https://eth-converter.com{' '}
                                </a>{' '}
                            </span>
                        </p>
                        <div className="panel-body">
                            <div className="field">
                                <label>
                                    Value:{' '}
                                    <span className="label label-primary">
                                        {ogSalePrice}
                                    </span>
                                </label>
                                <div className="control">
                                    <input
                                        className="form-control"
                                        type="text"
                                        onChange={e =>
                                            setOgSaleTokenNewCost(
                                                e.target.value
                                            )
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="panel-footer">
                            {currentAccount ? updateOgSaleTokenCost() : ''}
                        </div>
                    </div>
                </div>
            </div>

            <div className="panel panel-default">
                <p className="panel-heading">Reserve Token</p>
                <div className="panel-body">
                    <label className="">No of Tokens</label>
                    <div className="control">
                        <input
                            className="form-control"
                            type="number"
                            min="1"
                            onChange={e =>
                                setNoOfTokenToReserve(e.target.value)
                            }
                        />
                    </div>
                </div>
                <div className="panel-footer">
                    {currentAccount ? reserveToken() : ''}
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="panel panel-default">
                        <p className="panel-heading">Airdrop</p>
                        <div className="panel-body">
                            <div className="col-md-6">
                                <div className="field">
                                    <label>Wallet Address </label>
                                    <div className="control">
                                        <textarea
                                            className="form-control"
                                            onChange={e =>
                                                setAdresstoAirdrop(
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="field">
                                    <label>QTY </label>
                                    <div className="control">
                                        <input
                                            className="form-control"
                                            type="number"
                                            min="1"
                                            onChange={e =>
                                                setQtytoAirdrop(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="panel-footer">
                            {currentAccount ? airdropToken() : ''}
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <div className="panel panel-default">
                        <p className="panel-heading">Update Base URI</p>
                        <div className="panel-body">
                            <div className="control">
                                <input
                                    className="form-control"
                                    type="text"
                                    onChange={e => setBaseUri(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="panel-footer">
                            {currentAccount ? updateUri() : ''}
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="panel panel-default">
                        <p className="panel-heading">Update Non Reveal URI</p>
                        <div className="panel-body">
                            <div className="control">
                                <input
                                    className="form-control"
                                    type="text"
                                    onChange={e =>
                                        setNonRevealUri(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                        <div className="panel-footer">
                            {currentAccount ? updateNonRevealUri() : ''}
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="panel panel-default">
                        <p className="panel-heading">
                            Update Max Mint Limit - Public
                        </p>
                        <div className="panel-body">
                            <div className="form-group">
                                <label className="">
                                    Value:{' '}
                                    <span className="label label-primary">
                                        {publicMaxMint}
                                    </span>
                                </label>
                                <div className="control">
                                    <input
                                        className="form-control"
                                        type="number"
                                        min="1"
                                        onChange={e =>
                                            setNewPublicMaxMint(e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="panel-footer">
                            {currentAccount ? updatePublicSaleMaxMint() : ''}
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="panel panel-default">
                        <p className="panel-heading">
                            Update Max Mint Limit - Presale
                        </p>
                        <div className="panel-body">
                            <div className="form-group">
                                <label className="">
                                    Value:{' '}
                                    <span className="label label-primary">
                                        {whitelistMaxMint}
                                    </span>
                                </label>
                                <div className="control">
                                    <input
                                        className="form-control"
                                        type="number"
                                        min="1"
                                        onChange={e =>
                                            setNewWhitelistMaxMint(
                                                e.target.value
                                            )
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="panel-footer">
                            {currentAccount ? updatePresaleMaxMint() : ''}
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="panel panel-default">
                        <p className="panel-heading">
                            Update Max Mint Limit - OG Sale
                        </p>
                        <div className="panel-body">
                            <div className="form-group">
                                <label className="">
                                    Value:{' '}
                                    <span className="label label-primary">
                                        {ogSaleMaxMint}
                                    </span>
                                </label>
                                <div className="control">
                                    <input
                                        className="form-control"
                                        type="number"
                                        min="1"
                                        onChange={e =>
                                            setNewOgSaleMaxMint(e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="panel-footer">
                            {currentAccount ? updateOgSaleMaxMint() : ''}
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    {revealStatus ? (
                        ''
                    ) : (
                        <div className="panel panel-default">
                            <p className="panel-heading">Reveal Metadata</p>
                            <div className="panel-body">
                                {currentAccount ? switchReveal() : ''}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Nft;
