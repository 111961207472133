import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import Home from './components/Home';
import Contact from './components/Contact';
import Mint from './components/Mint';
import WalletCheck from './components/WalletCheck';
import Navbar from './components/Navbar';
import Nft from './components/Nft';

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/peeplistcheck" element={<WalletCheck />}>
                    PeepList Check
                </Route>
                <Route path="/" element={<Home />}></Route>
                <Route
                    path="/contact"
                    element={
                        <>
                            <Contact />
                        </>
                    }
                ></Route>
                <Route path="/admin-login" element={<Login />}></Route>
                <Route
                    path="/dashboard"
                    element={
                        <>
                            <Navbar /> <Nft />
                        </>
                    }
                ></Route>

                <Route path="*" element={<Home />}></Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
