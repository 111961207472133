import { Toaster } from 'react-hot-toast';
// eslint-disable-next-line

import logo from '../img/logo_xl.png';
import header_girl from '../img/header_girl.png';
import header_boy from '../img/header_boy.png';
import Header from './Header';

import header_video from '../videos/header.mp4';

import scroll_item01 from '../img/scroll_items/scroll_item01.png';
import scroll_item02 from '../img/scroll_items/scroll_item02.png';
import scroll_item03 from '../img/scroll_items/scroll_item03.png';
import scroll_item04 from '../img/scroll_items/scroll_item04.png';
import scroll_item05 from '../img/scroll_items/scroll_item05.png';
import scroll_item06 from '../img/scroll_items/scroll_item06.png';
import scroll_item07 from '../img/scroll_items/scroll_item07.png';
import scroll_item08 from '../img/scroll_items/scroll_item08.png';
import scroll_item09 from '../img/scroll_items/scroll_item09.png';
import scroll_item10 from '../img/scroll_items/scroll_item10.png';

import slider_nav from '../img/slider_nav.svg';

import slider_item01 from '../img/slider/01.png';
import slider_item02 from '../img/slider/02.png';
import slider_item03 from '../img/slider/03.png';
import slider_item04 from '../img/slider/04.png';
import slider_item05 from '../img/slider/05.png';
import slider_item06 from '../img/slider/06.png';
import slider_item07 from '../img/slider/07.png';
import slider_item08 from '../img/slider/08.png';
import slider_item09 from '../img/slider/09.png';
import slider_item10 from '../img/slider/10.png';
import slider_item11 from '../img/slider/11.png';
import slider_item12 from '../img/slider/12.png';
import slider_item13 from '../img/slider/13.png';
import slider_item14 from '../img/slider/14.png';
import slider_item15 from '../img/slider/15.png';

import team01 from '../img/team/team01.png';
import team02 from '../img/team/team02.png';
import team03 from '../img/team/team03.png';
import team04 from '../img/team/team04.png';
import team05 from '../img/team/team05.png';
import team06 from '../img/team/team06.png';
import team07 from '../img/team/team07.png';
import team08 from '../img/team/team08.png';
import team09 from '../img/team/team09.png';
import team10 from '../img/team/team10.png';
import team11 from '../img/team/team11.png';
import team12 from '../img/team/team12.png';

import raodmap from '../img/raodmap.png';
import map from '../img/map.png';
import collabs from '../img/collabs.png';

import future_collections from '../img/future_collections.png';
import future_collection_inner from '../img/future_collection_inner.png';
import threed_peeps_collections from '../img/3D-SAAD.png';

import footer_1 from '../img/footer_1.jpg';
import footer_2 from '../img/footer_2.png';

import discord from '../img/discord.svg';
import twitter from '../img/twitter.svg';
import youtube from '../img/youtube.png';

import video_white from '../img/video_white.png';
import discord_white from '../img/discord_white.png';
import twitter_white from '../img/twitter_white.png';

import Faq from './Faq';
import FooterCopyright from './common/FooterCopyright';

const Home = () => {
    document.body.classList.add('home-page');
    // eslint-disable-next-line
    return (
        <div>
            <Toaster
                position="top-center"
                reverseOrder={false}
                gutter={8}
                containerClassName=""
                containerStyle={{}}
                toastOptions={{
                    // Define default options
                    className: '',
                    duration: 5000,
                    style: {
                        background: '#363636',
                        color: '#fff',
                        maxWidth: 600,
                        marginTop: 26
                    },
                    // Default options for specific types
                    success: {
                        duration: 3000,
                        theme: {
                            primary: 'green',
                            secondary: 'black'
                        }
                    }
                }}
            />

            <Header />

            <header id="header" className="header-container">
                <div className="video-container">
                    <video className="bg-video" autoPlay loop muted playsInline>
                        <source src={header_video} type="video/mp4" />
                    </video>
                </div>
                <div className="container">
                    <div className="header-content-container text-center">
                        <div className="top">
                            <img src={logo} className="logo" alt="logo" />
                            <p>The arrival of a new era,</p>
                            <p>from a galaxy very far far away...</p>
                        </div>

                        <div className="btn-container">
                            <a
                                type="button"
                                data-toggle="modal"
                                href="#/"
                                data-target="#trailerModal"
                                className="btns btn-trailer"
                                rel="noreferrer"
                            >
                                <img
                                    src={video_white}
                                    className="logo"
                                    alt="video"
                                />
                            </a>
                            <a
                                href="https://discord.gg/galaxypeeps"
                                target="_blank"
                                className="btns btn-discord"
                                rel="noreferrer"
                            >
                                <img
                                    src={discord_white}
                                    className="logo"
                                    alt="discord"
                                />
                            </a>
                            <a
                                href="https://twitter.com/GalaxyPeepsNFT"
                                target="_blank"
                                className="btns btn-tw"
                                rel="noreferrer"
                            >
                                <img
                                    src={twitter_white}
                                    className="logo"
                                    alt="twitter"
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </header>

            <section id="about" className="about-container">
                <div className="container">
                    <h2 className="section-title mb-60">
                        Who We <span className="text-accent">Are</span>
                    </h2>
                    <p className="text-2-columns">
                        We are a story-driven humor-based fun-loving collection
                        of wonderful and witty art meticulously hand-drawn by
                        our artists. Our unique 7,777 Genesis NFTs collection
                        comprises over a hundred story characters, which serve
                        as the basis of our creative art pieces and storylines.
                        The collection is designed with a long-term
                        growth-oriented vision for the Metaverse world. Each
                        Galaxy Peeps NFT will grant holders full intellectual
                        property (IP) rights over their NFTs, exclusive VIP
                        discord access, the creator NFT marketplace, future
                        airdrops, upcoming free mint and 3D projects exclusively
                        reserved for GP holders, a token into our ecosystem, and
                        more. Our goal is to establish a successful IP brand
                        while building our current and future NFT projects
                        around it. We are building an enduring & robust
                        ecosystem with utility and diversification. Our vision
                        is to become an innovative global Web3 IP brand that
                        embodies many sectors, including the entertainment
                        business, consumer goods, animation, games, and
                        Metaverse platform integration. In addition, we strive
                        to partner with other creators and enterprises to
                        establish their web3 presence, as it's a collaborative
                        team effort where everyone maximizes their most vital
                        talents to the fullest. May the fun be with you all!
                    </p>

                    <h2 className="section-title mt-40 mb-60">
                        About Our <span className="text-accent">Art</span>
                    </h2>
                    <p className="text-2-columns">
                        Our collection is inspired by anime, pop culture, and
                        classic science fiction movies and pays homage to those
                        popular titles in a humorous and funny way, with
                        original stories and character designs from the quirky
                        minds of our creators.
                    </p>
                </div>
            </section>

            <section className="on-scroll-slider">
                <img
                    src={scroll_item01}
                    className="scroll-slider-item"
                    alt="logo"
                />
                <img
                    src={scroll_item02}
                    className="scroll-slider-item"
                    alt="logo"
                />
                <img
                    src={scroll_item03}
                    className="scroll-slider-item"
                    alt="logo"
                />
                <img
                    src={scroll_item04}
                    className="scroll-slider-item"
                    alt="logo"
                />
                <img
                    src={scroll_item05}
                    className="scroll-slider-item"
                    alt="logo"
                />
                <img
                    src={scroll_item06}
                    className="scroll-slider-item"
                    alt="logo"
                />
                <img
                    src={scroll_item07}
                    className="scroll-slider-item"
                    alt="logo"
                />
                <img
                    src={scroll_item08}
                    className="scroll-slider-item"
                    alt="logo"
                />
                <img
                    src={scroll_item09}
                    className="scroll-slider-item"
                    alt="logo"
                />
                <img
                    src={scroll_item10}
                    className="scroll-slider-item"
                    alt="logo"
                />
                <img
                    src={scroll_item01}
                    className="scroll-slider-item"
                    alt="logo"
                />
                <img
                    src={scroll_item02}
                    className="scroll-slider-item"
                    alt="logo"
                />
                <img
                    src={scroll_item03}
                    className="scroll-slider-item"
                    alt="logo"
                />
                <img
                    src={scroll_item04}
                    className="scroll-slider-item"
                    alt="logo"
                />
                <img
                    src={scroll_item05}
                    className="scroll-slider-item"
                    alt="logo"
                />
                <img
                    src={scroll_item06}
                    className="scroll-slider-item"
                    alt="logo"
                />
                <img
                    src={scroll_item07}
                    className="scroll-slider-item"
                    alt="logo"
                />
                <img
                    src={scroll_item08}
                    className="scroll-slider-item"
                    alt="logo"
                />
                <img
                    src={scroll_item09}
                    className="scroll-slider-item"
                    alt="logo"
                />
                <img
                    src={scroll_item10}
                    className="scroll-slider-item"
                    alt="logo"
                />
            </section>

            <section id="roadmap" className="roadmap-container">
                <div className="container">
                    <h2 className="section-title mb-60">
                        Our <span className="text-accent">Roadmap</span>
                    </h2>
                    <div className="img-container">
                        <img
                            src={raodmap}
                            className="scroll-slider-item"
                            alt="logo"
                        />
                    </div>
                </div>
            </section>

            <section id="future" className="future-collections-container">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-5 left">
                            <h2 className="section-title mb-60">
                                Our{' '}
                                <span className="text-accent">
                                    Future Collections
                                </span>
                            </h2>
                            <div className="img-container">
                                <img
                                    src={future_collections}
                                    className="scroll-slider-item"
                                    alt="logo"
                                />
                            </div>
                        </div>
                        <div className="col-sm-7 right">
                            <div
                                className="panel-group"
                                id="futureCollapse"
                                role="tablist"
                                aria-multiselectable="true"
                            >
                                <div className="panel panel-default">
                                    <div
                                        className="panel-heading"
                                        role="tab"
                                        id="heading1"
                                    >
                                        <h4 className="panel-title">
                                            <a
                                                role="button"
                                                data-toggle="collapse"
                                                data-parent="#futureCollapse"
                                                href="#collapse1"
                                                aria-expanded="true"
                                                aria-controls="collapse1"
                                                className="collapsed"
                                            >
                                                Galaxy Pen Pen Collection
                                                <span
                                                    className="glyphicon glyphicon-menu-down"
                                                    aria-hidden="true"
                                                ></span>
                                            </a>
                                        </h4>
                                    </div>
                                    <div
                                        id="collapse1"
                                        className="panel-collapse collapse"
                                        role="tabpanel"
                                        aria-labelledby="heading1"
                                    >
                                        <div className="panel-body">
                                            <p>
                                                This will be a FREE MINT for all
                                                Galaxy Peeps holders (You will
                                                be able to mint one per Galaxy
                                                Peeps that you own). After
                                                holders claim their FREE MINT
                                                Pen Pen NFTs, the Ostar Council
                                                will open this collection up to
                                                other minters! Every Galaxy
                                                Peeps holder can refer up to 5
                                                friends to mint a Pen Pen and
                                                earn 50% of the mintprice for
                                                that Pen Pen.
                                            </p>
                                            <img
                                                src={future_collection_inner}
                                                className="scroll-slider-item"
                                                alt="banner"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="panel panel-default">
                                    <div
                                        className="panel-heading"
                                        role="tab"
                                        id="heading2"
                                    >
                                        <h4 className="panel-title">
                                            <a
                                                role="button"
                                                data-toggle="collapse"
                                                data-parent="#futureCollapse"
                                                href="#collapse2"
                                                aria-expanded="true"
                                                aria-controls="collapse2"
                                                className="collapsed"
                                            >
                                                PixPeeps Collection
                                                <span
                                                    className="glyphicon glyphicon-menu-down"
                                                    aria-hidden="true"
                                                ></span>
                                            </a>
                                        </h4>
                                    </div>
                                    <div
                                        id="collapse2"
                                        className="panel-collapse collapse"
                                        role="tabpanel"
                                        aria-labelledby="heading2"
                                    >
                                        <div className="panel-body">
                                            <p>
                                                These pixel Galaxy Peeps NFTs
                                                will be a FREE MINT for all
                                                Galaxy Peeps holders (You will
                                                be able to mint one per Galaxy
                                                Peeps that you own). After all
                                                holders claim their free
                                                PixPeeps NFTs, the Ostar Council
                                                will open this collection up to
                                                other minters, and, as a holder,
                                                you&apos;ll be able to earn from
                                                this. More details will follow.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="panel panel-default">
                                    <div
                                        className="panel-heading"
                                        role="tab"
                                        id="heading3"
                                    >
                                        <h4 className="panel-title">
                                            <a
                                                role="button"
                                                data-toggle="collapse"
                                                data-parent="#futureCollapse"
                                                href="#collapse3"
                                                aria-expanded="true"
                                                aria-controls="collapse3"
                                                className="collapsed"
                                            >
                                                3D Galaxy Peeps Collection
                                                <span
                                                    className="glyphicon glyphicon-menu-down"
                                                    aria-hidden="true"
                                                ></span>
                                            </a>
                                        </h4>
                                    </div>
                                    <div
                                        id="collapse3"
                                        className="panel-collapse collapse"
                                        role="tabpanel"
                                        aria-labelledby="heading3"
                                    >
                                        <div className="panel-body">
                                            <p>
                                                As a part of our GP IP brand
                                                ecosystem, we will build a 3D
                                                version of Galaxy Peeps NFTs.
                                                Galaxy Peeps holders will
                                                receive GUARANTEED PeepLists &
                                                get full access to mint a 3D
                                                version - this will be reserved
                                                ONLY for Galaxy Peep Holders.
                                            </p>
                                            <img
                                                src={threed_peeps_collections}
                                                className="scroll-slider-item"
                                                alt="banner"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="panel panel-default">
                                    <div
                                        className="panel-heading"
                                        role="tab"
                                        id="heading4"
                                    >
                                        <h4 className="panel-title">
                                            <a
                                                role="button"
                                                data-toggle="collapse"
                                                data-parent="#futureCollapse"
                                                href="#collapse4"
                                                aria-expanded="true"
                                                aria-controls="collapse4"
                                                className="collapsed"
                                            >
                                                More To Come
                                                <span
                                                    className="glyphicon glyphicon-menu-down"
                                                    aria-hidden="true"
                                                ></span>
                                            </a>
                                        </h4>
                                    </div>
                                    <div
                                        id="collapse4"
                                        className="panel-collapse collapse"
                                        role="tabpanel"
                                        aria-labelledby="heading4"
                                    >
                                        <div className="panel-body">
                                            <p>More details will follow.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="peeps" className="peeps-container">
                <div className="container">
                    <div className="title-nav-container">
                        <h2 className="section-title mb-60">
                            Our <span className="text-accent">Peeps</span>
                        </h2>
                        <div className="nav-buttons-container">
                            <a
                                href="#123"
                                role="button"
                                className="slider-prev slider-nav left"
                            >
                                <img
                                    src={slider_nav}
                                    className="scroll-slider-item"
                                    alt="logo"
                                />
                            </a>
                            <a
                                href="#123"
                                role="button"
                                className="slider-next slider-nav right"
                            >
                                <img
                                    src={slider_nav}
                                    className="scroll-slider-item"
                                    alt="logo"
                                />
                            </a>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="owl-carousel owl-theme">
                                <div className="item">
                                    <img
                                        src={slider_item01}
                                        className="scroll-slider-item"
                                        alt="logo"
                                    />
                                </div>
                                <div className="item">
                                    <img
                                        src={slider_item02}
                                        className="scroll-slider-item"
                                        alt="logo"
                                    />
                                </div>
                                <div className="item">
                                    <img
                                        src={slider_item03}
                                        className="scroll-slider-item"
                                        alt="logo"
                                    />
                                </div>
                                <div className="item">
                                    <img
                                        src={slider_item04}
                                        className="scroll-slider-item"
                                        alt="logo"
                                    />
                                </div>
                                <div className="item">
                                    <img
                                        src={slider_item05}
                                        className="scroll-slider-item"
                                        alt="logo"
                                    />
                                </div>
                                <div className="item">
                                    <img
                                        src={slider_item06}
                                        className="scroll-slider-item"
                                        alt="logo"
                                    />
                                </div>
                                <div className="item">
                                    <img
                                        src={slider_item07}
                                        className="scroll-slider-item"
                                        alt="logo"
                                    />
                                </div>
                                <div className="item">
                                    <img
                                        src={slider_item08}
                                        className="scroll-slider-item"
                                        alt="logo"
                                    />
                                </div>
                                <div className="item">
                                    <img
                                        src={slider_item09}
                                        className="scroll-slider-item"
                                        alt="logo"
                                    />
                                </div>
                                <div className="item">
                                    <img
                                        src={slider_item10}
                                        className="scroll-slider-item"
                                        alt="logo"
                                    />
                                </div>
                                <div className="item">
                                    <img
                                        src={slider_item11}
                                        className="scroll-slider-item"
                                        alt="logo"
                                    />
                                </div>
                                <div className="item">
                                    <img
                                        src={slider_item12}
                                        className="scroll-slider-item"
                                        alt="logo"
                                    />
                                </div>
                                <div className="item">
                                    <img
                                        src={slider_item13}
                                        className="scroll-slider-item"
                                        alt="logo"
                                    />
                                </div>
                                <div className="item">
                                    <img
                                        src={slider_item14}
                                        className="scroll-slider-item"
                                        alt="logo"
                                    />
                                </div>
                                <div className="item">
                                    <img
                                        src={slider_item15}
                                        className="scroll-slider-item"
                                        alt="logo"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="map" className="map-container">
                <div className="container">
                    <h2 className="section-title mb-60">
                        Peeps Relationship{' '}
                        <span className="text-accent">Map</span>
                    </h2>
                    <div className="img-container">
                        <img
                            src={map}
                            className="scroll-slider-item"
                            alt="logo"
                        />
                    </div>
                </div>
            </section>

            <section id="team" className="team-container">
                <div className="container">
                    <h2 className="section-title mb-60">
                        Creative <span className="text-accent">Team</span>
                    </h2>
                    <div className="row">
                        <div className="col-md-4 col-sm-6">
                            <div className="inner">
                                <a
                                    href="https://twitter.com/Skykaynft"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        src={team01}
                                        className="team-item"
                                        alt="Jacky"
                                    />
                                </a>
                                <h3>Jacky</h3>
                                <p>Artistic Director/Dev</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="inner">
                                <a
                                    href="https://twitter.com/AngelaMimiNFT"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        src={team02}
                                        className="team-item"
                                        alt="Angela"
                                    />
                                </a>
                                <h3>Angela</h3>
                                <p>Chief Artist/Creator</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="inner">
                                <a
                                    href="https://twitter.com/PopchaserCrypto"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        src={team03}
                                        className="team-item"
                                        alt="Sam"
                                    />
                                </a>
                                <h3>Sam</h3>
                                <p>Marketing/Copywriter</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="inner">
                                <a
                                    href="https://twitter.com/RhysHall107"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        src={team04}
                                        className="team-item"
                                        alt="Reece"
                                    />
                                </a>
                                <h3>Reece</h3>
                                <p>Strategic Advisor/Marketing Chief</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="inner">
                                <a
                                    href="https://www.linkedin.com/in/miltonsoong/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        src={team05}
                                        className="team-item"
                                        alt="Milton"
                                    />
                                </a>
                                <h3>Milton</h3>
                                <p>Business Advisor</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="inner">
                                <a
                                    href="https://twitter.com/web3elab"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        src={team11}
                                        className="team-item"
                                        alt="Shan"
                                    />
                                </a>
                                <h3>Shan</h3>
                                <p>Blockchain Developer</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="inner">
                                <a
                                    href="https://twitter.com/sanleo461"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        src={team12}
                                        className="team-item"
                                        alt="SanLeo"
                                    />
                                </a>
                                <h3>SanLeo</h3>
                                <p>Web3 Developer</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="inner">
                                <a
                                    href="https://linktr.ee/kristn"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        src={team06}
                                        className="team-item"
                                        alt="Kristn"
                                    />
                                </a>
                                <h3>Kristn</h3>
                                <p>Collab Manager</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="inner">
                                <a
                                    href="https://twitter.com/No_nyee"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        src={team07}
                                        className="team-item"
                                        alt="GooNo"
                                    />
                                </a>
                                <h3>GooNo</h3>
                                <p>Collab Manager</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="inner">
                                <a
                                    href="https://twitter.com/3amdexter"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        src={team08}
                                        className="team-item"
                                        alt="0xDTS"
                                    />
                                </a>
                                <h3>0xDTS</h3>
                                <p>Collab Manager</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="inner">
                                <a
                                    href="https://twitter.com/Lovee_eth"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        src={team09}
                                        className="team-item"
                                        alt="Love"
                                    />
                                </a>
                                <h3>Love</h3>
                                <p>Community Team</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="inner">
                                <a
                                    href="https://twitter.com/MonsieurLaBoom"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        src={team08}
                                        className="team-item"
                                        alt="Monsieur"
                                    />
                                </a>
                                <h3>Monsieur</h3>
                                <p>Community Team</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="inner">
                                <a
                                    href="https://twitter.com/kevgreat4_d"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        src={team10}
                                        className="team-item"
                                        alt="Kevins"
                                    />
                                </a>
                                <h3>Kevins</h3>
                                <p>Community Team</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="inner">
                                <a
                                    href="https://twitter.com/speedajaks"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        src={team06}
                                        className="team-item"
                                        alt="Kennys"
                                    />
                                </a>
                                <h3>Kennys</h3>
                                <p>Community Team</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="community" className="community-container">
                <div className="container">
                    <div className="inner">
                        <h2 className="section-title ">
                            Join the{' '}
                            <span className="text-accent">Community</span>
                        </h2>
                        <p>
                            Galaxy Peeps is a community-centered project built
                            on the honest collaboration and free spirited
                            connections of all its members, whose voices are
                            respected and valued by all. We empower our
                            community by letting the community decide and vote
                            on future proposals that affect not just our
                            business but the whole ecosystem as well.
                            <br />
                            <br />
                        </p>
                        <div className="links-container">
                            <a
                                href="https://discord.gg/galaxypeeps"
                                target="_blank"
                                className="btns btn-discord"
                                rel="noreferrer"
                            >
                                <img
                                    src={discord_white}
                                    className="logo"
                                    alt="discord"
                                />
                            </a>
                            <a
                                href="https://twitter.com/GalaxyPeepsNFT"
                                target="_blank"
                                className="btns btn-tw"
                                rel="noreferrer"
                            >
                                <img
                                    src={twitter_white}
                                    className="logo"
                                    alt="twitter"
                                />
                            </a>
                        </div>
                        <div className="imgs">
                            <img
                                src={header_girl}
                                className="img-item1"
                                alt="logo"
                            />
                            <img
                                src={header_boy}
                                className="img-item2"
                                alt="logo"
                            />
                        </div>
                    </div>
                </div>
            </section>
            <Faq />
            <section id="collaborations" className="collaborations-container">
                <div className="container">
                    <h2 className="section-title mb-60">
                        Our <span className="text-accent">Collaborations</span>
                    </h2>
                    <div className="img-container">
                        <img
                            src={collabs}
                            className="scroll-slider-item"
                            alt="collabs"
                        />
                    </div>
                </div>
            </section>
            <section id="map" className="map-container">
                <div className="container">
                    <h2 className="section-title mb-60">
                        As <span className="text-accent">Listed On</span>
                    </h2>
                    <div className="row">
                        <div className="col-sm-6">
                            <a
                                href="https://niftydrops.io/nft-drops/galaxy-peeps"
                                target="_blank"
                                className="btn"
                                rel="noreferrer"
                            >
                                <img
                                    src={footer_1}
                                    className="scroll-slider-item"
                                    alt="logo"
                                />
                            </a>
                        </div>
                        <div className="col-sm-6">
                            <a
                                href="https://nftdroops.com/nft/galaxy-peeps/"
                                target="_blank"
                                className="btn"
                                rel="noreferrer"
                            >
                                <img
                                    src={footer_2}
                                    className="scroll-slider-item"
                                    alt="logo"
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <footer>
                <div className="container">
                    <div className="grid">
                        <div className="left"></div>
                        <div className="center">
                            <a href="/contact" className="footer-link">
                                Contacts
                            </a>
                        </div>
                        <div className="right">
                            <a
                                href="https://discord.gg/galaxypeeps"
                                target="_blank"
                                className="social-icon"
                                rel="noreferrer"
                            >
                                <img src={discord} className="" alt="discord" />
                            </a>
                            <a
                                href="https://twitter.com/GalaxyPeepsNFT"
                                target="_blank"
                                className="social-icon"
                                rel="noreferrer"
                            >
                                <img src={twitter} className="" alt="twitter" />
                            </a>
                            <a
                                href="https://www.youtube.com/channel/UCAE1CAbYyZ-lbED8oBA9y0Q"
                                target="_blank"
                                className="social-icon"
                                rel="noreferrer"
                            >
                                <img src={youtube} className="" alt="youtube" />
                            </a>
                        </div>
                    </div>
                    <div className="reserved">
                        <FooterCopyright />
                    </div>
                </div>
            </footer>

            <div
                className="modal fade"
                id="trailerModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="trailerModalLabel"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <div className="modal-body">
                            <iframe
                                className="embedly-embed"
                                src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FOo29ObsEX_Y%3Ffeature%3Doembed&amp;display_name=YouTube&amp;url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DOo29ObsEX_Y&amp;image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FOo29ObsEX_Y%2Fhqdefault.jpg&amp;key=96f1f04c5f4143bcb0f2e68c87d65feb&amp;type=text%2Fhtml&amp;schema=youtube"
                                scrolling="no"
                                allowFullScreen=""
                                title="Galaxy Peeps ~ NEW🔥HOT🔥NFT project you have been waiting for a very very very long time!!"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Home;
